import React from 'react';
import { graphql } from 'gatsby';

import BasePage from './BasePage';

const PolicyPage = ({ data, pageContext }) => {
  const post = data.markdownRemark;

  // Sometimes during a hot reload, `markdownRemark` is undefined
  if (!post) {
    return null;
  }

  let title = post.frontmatter.title ?? post.headings?.[0]?.value ?? '';

  let description = post.frontmatter.description || '';

  return (
    <BasePage
      pageContext={{ ...pageContext, title, description, markdown: post.html, hideTitle: true }}
    />
  );
};

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
      headings {
        value
      }
      fields {
        slug
      }
    }
  }
`;

export default PolicyPage;
